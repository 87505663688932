import request from "@/utils/request";

// 浏览新闻信息
export function getNew(data) {
  return request({
    url: "/system/News/getNewsTimeortypeList",
    method: "post",
    data
  });
}

// 删除新闻信息
export function deleteNew(data) {
  return request({
    url: "/system/News/DeleteNews",
    method: "post",
    data
  });
}

// 新闻单个信息
export function getNewsInfo(query) {
  return request({
    url: "/system/News/getNewsInfo",
    method: "get",
    params: query
  });
}

// 修改新闻信息
export function editNew(data) {
  return request({
    url: "/system/News/UpdateNews",
    method: "post",
    data
  });
}

// 资讯来源接口
// 一览
export function listRecruitmentResourcePage(data) {
  return request({
    url: "/system/newsSource/listRecruitmentResourcePage",
    method: "post",
    data
  });
}

// 删除
export function newsSourceDeleteById(data) {
  return request({
    url: "/system/newsSource/deleteById",
    method: "get",
    params: data
  });
}

// 新增
export function insertNewsSource(data) {
  return request({
    url: "/system/newsSource/insertNewsSource",
    method: "post",
    data
  });
}

// 修改
export function updateNewsSource(data) {
  return request({
    url: "/system/newsSource/updateNewsSource",
    method: "post",
    data
  });
}

// 单个查询
export function selectById(data) {
  return request({
    url: "/system/newsSource/selectById",
    method: "get",
    params: data
  });
}

// 单个查询资讯总数
export function listRecruitmentResourcePageCount(data) {
  return request({
    url: "/system/newsSource/listRecruitmentResourcePageCount",
    method: "post",
    data
  });
}

// 单个查询今日新增资讯总数
export function listRecruitmentResourcePageTodayCount(data) {
  return request({
    url: "/system/newsSource/listRecruitmentResourcePageTodayCount",
    method: "post",
    data
  });
}

// 周新增资讯总数
export function listRecruitmentResourcePageOutputNum(data) {
  return request({
    url: "/system/newsSource/listRecruitmentResourcePageOutputNum",
    method: "post",
    data
  });
}

// 导入智参数据库
export function importInternal(data) {
  return request({
    url: "/system/News/importInternal",
    method: "post",
    data
  });
}


// 单个查询
export function getAllCompanyList(data) {
  return request({
    url: "/system/company/getAllCompanyList",
    method: "get",
    params: data
  });
}

// 基本信息-修改
export function editNewsInfo(data) {
  return request({
    url: "/system/News/editNewsInfo",
    method: "post",
    data
  });
}

// 标签信息-编辑
export function editNewsKeywordLabel(data) {
  return request({
    url: "/system/News/editNewsKeywordLabel",
    method: "post",
    data
  });
}

// 标签信息-查询
export function getNewsKeywordLabel(data) {
  return request({
    url: "/system/News/getNewsKeywordLabel",
    method: "get",
    params: data
  });
}

// 手动标签-编辑（保存）
export function insertHandLabel(data) {
  return request({
    url: "/system/News/insertHandLabel",
    method: "post",
    data
  });
}

// 手动标签-查询
export function getById(data) {
  return request({
    url: "/system/News/getById",
    method: "get",
    params: data
  });
}

// 获取半自动历史查询标签
export function getMatchLabel(data) {
  return request({
    url: "/system/News/label/getMatchLabel",
    method: "get",
    params: data
  });
}

// 自动化标签-新增
export function insertMatchNewsLabel(data) {
  return request({
    url: "/system/News/label/insertMatchNewsLabel",
    method: "post",
    data
  });
}
