<template>
  <div>
    <div>
      <div class="newsLook">
        <el-form
          label-width="120px"
          :model="newsData"
          :rules="newsDataRules"
          ref="fieldRef"
          v-if="isShow"
        >
          <el-form-item label="网站名称：" prop="siteName">
            <el-input v-model="newsData.siteName" disabled></el-input>
          </el-form-item>
          <el-form-item label="网站类型：">
            <el-input v-model="newsData.siteType"></el-input>
          </el-form-item>
          <el-form-item label="网站链接：">
            <el-input v-model="newsData.siteLink"></el-input>
          </el-form-item>
          <el-form-item label="采编类型：" prop="screen">
            <el-radio-group v-model="newsData.editorialType">
              <el-radio label="转载">转载</el-radio>
              <el-radio label="原创">原创</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所属公司：">
            <el-input v-model="newsData.belongCompany"></el-input>
          </el-form-item>
          <el-form-item label="所在地区：">
            <cascader-area
              :country="newsData.country"
              :province="newsData.province"
              :city="newsData.city"
              @searchCountry="searchCountry"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
            />
          </el-form-item>
          <el-form-item label="资讯个数：">
            <el-input
              v-model.number="newsData.recruitmentCount"
              type="number"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="今日新增个数：">
            <el-input
              v-model.number="newsData.todayAddNum"
              type="number"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="资讯来源描述：">
            <el-input
              v-model="newsData.resourceDescribe"
              :autosize="{ minRows: 4, maxRows: 20 }"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="周产出数量：">
            <el-input
              v-model.number="newsData.outputNum"
              disabled
              type="number"
            ></el-input>
          </el-form-item>
          <div class="flexStyle">
            <el-form-item label="相关度：">
              <el-select
                v-model="newsData.relevance"
                placeholder="请选择"
                style="width:100px"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="知名度：">
              <el-select
                v-model="newsData.profile"
                placeholder="请选择"
                style="width:100px"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="活跃度：">
              <el-select
                v-model="newsData.active"
                placeholder="请选择"
                style="width:100px"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资讯等级：">
              <el-select
                v-model="newsData.level"
                placeholder="请选择"
                style="width:100px"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div class="company_right" v-if="personType">
          <div>创建人：{{ newsData.createName }}</div>
          <div>修改人：{{ newsData.updateName }}</div>
          <div>创建时间：{{ newsData.createTime }}</div>
          <div>修改时间：{{ newsData.updateTime }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="btnClick">确定</el-button>
      <el-button @click="cancelItem">返回</el-button>
    </div>
  </div>
</template>

<script>
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import { insertNewsSource, updateNewsSource, selectById } from "@/api/news";
import CascaderArea from "@/components/CascaderArea.vue";
export default {
  name: "newsInfo",
  components: {
    DicRadioGroup,
    CascaderArea
  },
  computed: {
    newsDatas() {
      return Number(this.$route.query.newsData);
    },
    personType() {
      return Number(this.$route.query.personType);
    },
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  watch: {
    "newsData.id": {
      handler(newVal) {
        if (this.newsData && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.personType) {
      this.search();
    }
    if (!this.personType) {
      this.isShow = true;
    }
  },
  data() {
    return {
      isShow: false,
      newsData: {
        siteName: "", //网站名称
        siteType: "", //网站类型
        editorialType: "", //采编类型
        belongCompany: "", //所属公司
        todayAddNum: null, //今日新增个数
        recruitmentCount: null, //资讯个数
        siteLink: "", //网站链接
        country: "",
        province: "",
        city: "",
        resourceDescribe: "", //描述
        level: "", //资讯等级
        outputNum: "", //资讯产出数量
        relevance: "", //相关度
        profile: "", //知名度
        active: "" //活跃度
      },
      newsDataRules: {
        siteName: [
          { required: true, message: "请输入网站名称", trigger: "blur" }
        ]
      },
      data: "",
      queryInfo: {
        positionType: ""
      },
      options: [1, 2, 3, 4, 5],
      list: ""
    };
  },
  methods: {
    cancelItem() {
      this.$router.go(-1);
    },
    async btnClick() {
      //修改
      Object.assign(this.newsData, {
        Authorization: this.Authorization
      });
      this.$refs.fieldRef.validate(async valid => {
        if (valid) {
          if (!this.personType) {
            const res = await insertNewsSource(this.newsData);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          } else {
            const res = await updateNewsSource(this.newsData);
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          }
        }
      });
    },
    async search() {
      const res = await selectById({ id: this.newsDatas });
      if (res.code == 200) {
        this.newsData = res.data;
      }
    },
    searchCountry(val) {
      this.newsData.country = val;
    },
    searchProvince(val) {
      this.newsData.province = val;
    },
    searchCity(val) {
      this.newsData.city = val;
    }
  },
  mounted() {
    document.getElementsByClassName("newsLook")[0].style.height =
      document.body.clientHeight - 220 + "px";
  }
};
</script>

<style lang="less" scoped>
.newsLook {
  background-color: white;
  padding: 30px 10px 10px 10px;
  overflow: auto;
  // height: 720px;
  .el-form {
    .el-form-item {
      margin-bottom: 20px;
      .el-textarea {
        width: 80%;
      }
      .el-input {
        width: 470px;
      }
      /deep/ .el-textarea__inner {
        height: 100%;
      }
    }
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: white;
  color: #606266;
  cursor: default;
}
.company_right {
  margin: 0px 20px;
  font-size: 13px;
  color: #7c7f8e;
}
.flexStyle {
  display: flex;
}
</style>
